<template>
  <div class="flex flex-col items-center justify-center w-full h-full diffusion">
    <h3 class="font-bold text-21px mb-4" v-html="$t('diffusion.title')"></h3>

    <p
      v-if="this.response.iQuestion_300 == '1'"
      class="text-center text-base mb-6"
      v-html="$t('diffusion.subtitle_email_already_exist')"
    ></p>
    <p v-else class="text-center text-base mb-6" v-html="$t('diffusion.subtitle_with_code')"></p>

    <div class="separator mb-6"></div>

    <p
      class="text-center text-base mb-16"
      v-html="$t('diffusion.subtitle_bis_with_code')"
      ref="diffusion-subtitle"
    ></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({ response: 'user/responses' }),
  },
}
</script>

<style lang="scss" scoped>
.separator {
  width: 30px;
  height: 2px;
  border-radius: 2px;
  background-color: #000;
}
.diffusion {
  @screen lg {
    height: 80vh;
  }
}
</style>
